import * as Sentry from "@sentry/browser";
import { Controller } from "@hotwired/stimulus";
import { GoogleAutocompleteResolver, ResolvedAddress } from "../../src/google_autocomplete_resolver";

interface AddressParams extends ResolvedAddress {
  street_address?: string;
}

export default class extends Controller {
  static targets = [
    "form", "field", "streetAddress", "sublocality", "locality",
    "manualEntryFieldsToggle", "manualEntryFields",
    "autocompleteEntryFieldsToggle", "autocompleteEntryFields",
    "addressErrorText", "addressErrorButton", "modal", "modalContent", "notServicedSection",
    "addressInputSection", "changeAddressButton", "applyButton"
  ];

  declare fieldTarget : HTMLInputElement;
  declare hasStreetAddressTarget: boolean;
  declare streetAddressTarget : HTMLInputElement;
  declare sublocalityTarget : HTMLInputElement;
  declare localityTarget : HTMLInputElement;
  declare formTarget : HTMLFormElement;
  declare autocompleteEntryFieldsTarget : HTMLElement;
  declare autocompleteEntryFieldsToggleTarget : HTMLElement;
  declare manualEntryFieldsTarget : HTMLElement;
  declare manualEntryFieldsToggleTarget : HTMLElement;
  declare addressErrorTextTarget: HTMLDivElement;
  declare addressErrorButtonTarget: HTMLElement;
  declare hasAddressErrorButtonTarget: boolean;
  declare modalTarget: HTMLDivElement;
  declare modalContentTarget: HTMLParagraphElement;
  declare notServicedSectionTarget: HTMLDivElement;
  declare addressInputSectionTarget: HTMLDivElement;
  declare changeAddressButtonTarget: HTMLButtonElement;
  declare hasChangeAddressButtonTarget: boolean;
  declare applyButtonTarget: HTMLButtonElement;
  declare hasApplyButtonTarget: boolean;

  autocomplete?: google.maps.places.Autocomplete;
  originalInput?: string;
  specificAddressChosen?: boolean;

  async connect(): Promise<void> {
    await google.maps.importLibrary("places");
    this.initAutocomplete();
  }

  initAutocomplete(): void {
    this.autocomplete = GoogleAutocompleteResolver.defaultAutoComplete(this.fieldTarget);
    this.autocomplete.addListener("place_changed", this.placeChanged.bind(this));

    this.fieldTarget.oninput = () => { this.fieldTarget.setCustomValidity(''); };
    this.fieldTarget.oninvalid = () => { this.fieldTarget.setCustomValidity('Please enter an address'); };
  }

  async placeChanged(): Promise<void> {
    if (this.fieldTarget.value == "") return;

    // If the user hits enter on a garbage address with no autocomplete results, we get a placeChanged but getPlace
    // returns a place that has no details filled in except the entered text. We can wait for resolveAutocomplete/
    // GoogleAutocompleteResolver#resolveFromAutocomplete to tell us this, but it's nice to give the feedback
    // straight away on the modals that don't use autosubmit.
    const place = this.autocomplete?.getPlace();
    if (!place?.address_components || !place?.types) {
      this.reportNoResults();
      return;
    }

    // We mark whether the customer explicitly chose from the autocomplete menu, as opposed to us taking the first result
    this.specificAddressChosen ??= true;

    if (this.data.get("autosubmit") !== "false") {
      this.submit();
    }

    if (this.hasChangeAddressButtonTarget) {
      this.changeAddressButtonTarget.disabled = false;
    }
  }

  async checkNewAddress(event: Event): Promise<void> {
    event.preventDefault();
    this.addressErrorTextTarget.innerText = "";

    const result = await this.resolveAutocomplete();
    if (!result) return;

    const metaElement = document.querySelector("meta[name='csrf-token']") as HTMLMetaElement;
    if (metaElement === undefined) {
      throw "Missing CSRF meta tag";
    }
    const csrfToken = metaElement?.content;

    const response = await fetch("orders/validate_new_address", {
      method: "POST",
      credentials: "include",
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': csrfToken
      },
      body: JSON.stringify(result)
    });

    const json = await response.json();

    if (response.status == 200) {
      await this.submitResult(result);
    } else if (response.status == 300) {
      this.modalContentTarget.innerText = json['confirmationText'];
      this.notServicedSectionTarget.classList.remove("is-completely-hidden");
      this.addressInputSectionTarget.classList.add("is-completely-hidden");
    } else {
      this.reportError(json['error'], false);
    }
  }

  closeModal() {
    this.modalContentTarget.innerText = "";
    this.modalTarget.classList.remove("is-active");
    this.fieldTarget.value = ""
    this.changeAddressButtonTarget.disabled = true;
    this.notServicedSectionTarget.classList.add("is-completely-hidden");
    this.addressInputSectionTarget.classList.remove("is-completely-hidden");
  }

  showModal(){
    this.modalTarget.classList.add("is-active");
  }

  async updateAddressAndRedirect(e: Event): Promise<void> {
    e.preventDefault()
    await this.submit()
    window.location.href = "/"
  }

  async buttonPressed(event: Event): Promise<void> {
    event.preventDefault();
    this.submit();
  }

  async submitResult(addressParams?: AddressParams): Promise<void> {
    if (this.hasApplyButtonTarget) {
      this.applyButtonTarget.classList.add("is-loading");
      this.applyButtonTarget.disabled = true;
    }

    // Grab the rest of the form
    const formData = new FormData(this.formTarget);
    const params = Object.fromEntries(formData.entries());

    // Add the selected address details, if any
    if (addressParams) Object.assign(params, addressParams);

    // This param isn't really very clearly named any more, but it's only used for debugging.
    if (this.specificAddressChosen) params.autocompleted_address = "true";

    const csrfToken = (document.querySelector("meta[name='csrf-token']") as HTMLMetaElement)?.content;

    const response = await fetch(this.formTarget.action, {
      method: "POST",
      credentials: "include",
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': csrfToken
      },
      body: JSON.stringify(params)
    });

    const json = await response.json();

    if (json.error) {
      this.reportError(json.error, json.support_link);
    } else {
      window.location.reload();
    }
  }

  async submit(): Promise<void> {
    if (!this.formTarget.checkValidity()) {
      // Blank input fields, bail out
      this.formTarget.reportValidity();
    } else if (this.hasStreetAddressTarget && this.streetAddressTarget.value != "") {
      // Manual entry (the "Can't find your address?" form), verify/geocode it
      const result = await this.resolveManualEntry();
      if (result) await this.submitResult(result);
    } else if (this.fieldTarget.value == "") {
      // No address change, but submit the rest of the form, for those that have multiple functions.
      await this.submitResult(undefined);
    } else if (!this.autocomplete?.getPlace()) {
      // If there's been typing in the autocomplete input that hasn't already been resolved by an
      // autocomplete selection, try to select an entry.
      //
      // Clicking the button will have removed focus from the autocomplete, making it remove its
      // list and stop listening to keydown events. Simulate a focus event to bring the list back,
      // a down-arrow to move to the first element in the list, then an Enter event to choose it.
      this.specificAddressChosen = false;
      this.fieldTarget.dispatchEvent(new UIEvent("focus", { view: window, bubbles: true, cancelable: true }));
      this.fieldTarget.dispatchEvent(new KeyboardEvent("keydown", { view: window, bubbles: true, cancelable: true, which: 40, keyCode: 40 }));
      this.fieldTarget.dispatchEvent(new KeyboardEvent("keydown", { view: window, bubbles: true, cancelable: true, which: 13, keyCode: 13, key: "Enter" }));
    } else {
      // Autocomplete entry selected, check and tweak it if necessary
      const result = await this.resolveAutocomplete();
      if (result) await this.submitResult(result);
    }
  }

  inputChanged(): void {
    // Capture the input for later debugging
    this.originalInput = this.fieldTarget.value;

    // Make it clear we haven't autocompleted (or geocoded!) the edited address so buttonPressed will do it
    if (this.hasStreetAddressTarget) {
      this.streetAddressTarget.value = "";
    }

    // And clear the autocompleted selection flag until inputChanged is called again
    this.specificAddressChosen = undefined;

    // Disable submit for the change address form until the place gets selected so we don't submit stale values
    if (this.hasChangeAddressButtonTarget) {
      this.changeAddressButtonTarget.disabled = true;
    }
  }

  private async resolveAutocomplete(): Promise<AddressParams | null> {
    const addressResolver = new GoogleAutocompleteResolver();
    const result = await addressResolver.resolveFromAutocomplete(this.fieldTarget.value, this.autocomplete?.getPlace());
    if (!result) {
      this.reportNoResults();
      return null;
    } else {
      return result;
    }
  }

  private async resolveManualEntry(): Promise<AddressParams | null> {
    const inputAddress = `${this.streetAddressTarget.value}, ${this.sublocalityTarget.value}, ${this.localityTarget.value}`;
    const resp = await fetch(`https://api.addressfinder.io/api/nz/address/verification/?key=${this.data.get('addressfinder-key')}&format=json&q=${encodeURIComponent(inputAddress)}`);
    const json = await resp.json();

    // It's not currently an error to fail to match, but we do report actual errors
    if (!json['success']) {
      Sentry.captureMessage(`AddressEntryController: verifying address returned ${json['success']}`, { extra: {
        inputAddress: inputAddress,
      } });
      this.reportError(`Sorry, we had a problem looking up your address.`, false);
      return null;
    } else if (json['matched']) {
      return {
        address_provider: "addressfinder",
        address: json.a,
        full_address: json.a,

        street: json.street,
        street_address: json.address_line_2 ? `${json.address_line_1}, ${json.address_line_2}` : json.address_line_1,
        locality: json.city,
        sublocality: json.suburb,
        postal_code: json.postcode,

        place_id: json.pxid,

        latitude: json.y,
        longitude: json.x,
      };
    } else {
      // Last chance: try again with Google - but using the geocoding service rather than the
      // autocomplete service, as in the manual entry scenario the user won't get a chance to see
      // what options are being suggested and we don't want to just guess. Geocoding is more
      // reliable in that it usually returns results it has some confidence about - it nearly
      // always resolves to *something*, but if it doesn't recognise the street address part,
      // it'll normally return a result for the suburb/city part, which our server code will
      // figure out is an incomplete address and reject. It's not perfect and we are still just
      // going to accept the first of N geocoding results returned, but in practice, after users
      // have rejected the Google autocomplete choices and switched to manual, and then entered
      // an address that AddressFinder also cannot resolve, most of what's left over to run
      // through this code is either incomplete or works out well using the top geocoding result.
      const addressResolver = new GoogleAutocompleteResolver();
      const result = await addressResolver.resolveFromGeocoding(inputAddress);
      if (result?.partial_match) {
        this.reportError(`Sorry, we're having trouble finding that address. Did you mean ${result?.full_address}?`, false);
        return null;
      } else if (!result) {
        this.reportError(`Sorry, we're having trouble finding an exact location for ${inputAddress}. Please enter a complete street address.`, false);
        return null;
      } else {
        const addressParams = result as AddressParams;
        addressParams.street_address = undefined; // let the server extract it from the full address, as the input is often not very good
        this.specificAddressChosen = false;
        return addressParams;
      }
    }
  }

  reportNoResults() {
    this.reportError(`Sorry, we're having trouble finding an exact location for ${this.fieldTarget.value}. Please enter a complete street address.`, false);
  }

  reportError(error: string, showContactButton: boolean): void {
    this.addressErrorTextTarget.innerText = error;
    this.addressErrorTextTarget.parentElement?.classList?.remove("is-completely-hidden");
    this.addressErrorTextTarget.parentElement?.classList?.add("has-error");

    if (this.hasAddressErrorButtonTarget) {
      if (showContactButton) {
        this.addressErrorButtonTarget.classList.remove("is-completely-hidden");
      } else {
        this.addressErrorButtonTarget.classList.add("is-completely-hidden");
      }
    }

    if (this.hasApplyButtonTarget) {
      this.applyButtonTarget.classList.remove("is-loading");
      this.applyButtonTarget.disabled = false;
    }
  }

  keydown(event : KeyboardEvent): void {
    if (event.key == "Enter") {
      // Stop the default form submission action, letting the Autocompleter code call placeChanged to submit
      event.preventDefault();

      if (!document.querySelector(".pac-item-selected")) {
        // No item's highlighted in the autocomplete menu yet, so move onto the first one, then let
        // the autocomplete's keydown handler select it. Mark this as not explicitly selected, too.
        this.specificAddressChosen = false;
        this.fieldTarget.dispatchEvent(new KeyboardEvent("keydown", { view: window, bubbles: true, cancelable: true, which: 40, keyCode: 40 }));
      }
    }
  }

  showManualEntry(): void {
    this.manualEntryFieldsTarget.classList.remove("is-completely-hidden");
    this.manualEntryFieldsToggleTarget.classList.add("is-completely-hidden");

    this.autocompleteEntryFieldsTarget.classList.add("is-completely-hidden");
    this.autocompleteEntryFieldsToggleTarget.classList.remove("is-completely-hidden");

    // We start these fields as hidden inputs so that they dont trigger Chrome's multi-field
    // address auto-fill behavior when you type in the main autocomplete box. But when you
    // switch to manual entry, we convert them to text field inputs.
    this.streetAddressTarget.setAttribute("type", "text");
    this.streetAddressTarget.setAttribute("autocomplete", "street-address");
    this.streetAddressTarget.disabled = false;
    this.streetAddressTarget.required = true;
    this.sublocalityTarget.setAttribute("type", "text");
    this.sublocalityTarget.setAttribute("autocomplete", "address-level3");
    this.sublocalityTarget.disabled = false;
    this.localityTarget.setAttribute("type", "text");
    this.localityTarget.setAttribute("autocomplete", "address-level2");
    this.localityTarget.disabled = false;
    this.localityTarget.required = true;

    // Disable and hide the autocomplete input so there's no confusion about what to do next -
    // and that Chrome autocomplete uses the manual street address field not the autocomplete one.
    this.fieldTarget.setAttribute("type", "hidden");
    this.fieldTarget.disabled = true;
    this.fieldTarget.required = false;
    this.fieldTarget.setCustomValidity("");
    this.fieldTarget.value = "";
  }

  hideManualEntry(): void {
    // Reverse each of the changes made above
    this.manualEntryFieldsTarget.classList.add("is-completely-hidden");
    this.manualEntryFieldsToggleTarget.classList.remove("is-completely-hidden");

    this.autocompleteEntryFieldsTarget.classList.remove("is-completely-hidden");
    this.autocompleteEntryFieldsToggleTarget.classList.add("is-completely-hidden");

    this.streetAddressTarget.setAttribute("type", "hidden");
    this.streetAddressTarget.removeAttribute("autocomplete");
    this.streetAddressTarget.disabled = true;
    this.streetAddressTarget.required = false;
    this.sublocalityTarget.setAttribute("type", "hidden");
    this.sublocalityTarget.removeAttribute("autocomplete");
    this.sublocalityTarget.disabled = true;
    this.localityTarget.setAttribute("type", "hidden");
    this.localityTarget.removeAttribute("autocomplete");
    this.localityTarget.disabled = true;
    this.localityTarget.required = false;

    this.fieldTarget.setAttribute("type", "text");
    this.fieldTarget.disabled = false;
    this.fieldTarget.required = true;
    this.fieldTarget.setCustomValidity("");
  }
}
